// ----------------------------------------------------------------------

import {User} from "../../../@types/user";
import ItemsTable from "../../../sections/ItemsTable";
import {useEffect} from "react";
import {Card} from "@mui/material";

interface Props {
  user: User;
}

export default function Items({ user }: Props) {
  useEffect(() => {
    console.log("items effect");
  }, []);

  return (
    <Card>
      <ItemsTable user={user} />
    </Card>
  );
}
