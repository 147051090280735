import { capitalCase } from 'change-case';
// @mui
import {Container, Tab, Box, Tabs, CircularProgress, Stack, Switch} from '@mui/material';
// routes
import { PATH_APP } from '../../../routes/paths';
// hooks
import useTabs from '../../../hooks/useTabs';
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import {Navigate, useParams} from "react-router-dom";
import General from "./General";
import {dispatch, useDispatch, useSelector} from "../../../redux/Store";
import {useEffect, useState} from "react";
import {getUser} from "../../../redux/repos/UserRepo";
import Items from "./Items";
import Label from "../../../components/Label";
import axios from "../../../utils/axios";
import {useSnackbar} from "notistack";

// ----------------------------------------------------------------------

export default function AdminUser() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const { data, loading } = useSelector((state) => state.userRepo);
  const user = (data && data.items.length != 0) ? data.items[0] : undefined;

  const [ working, setWorking ] = useState<boolean>(false);
  const [ enabledSwitch, setEnabledSwitch ] = useState(false);
  const [ enabledLabel, setEnabledLabel ] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  let { tab, id } = useParams();

  useEffect(() => {
    if(!id) return;
    dispatch(getUser(id));
  }, [id]);

  useEffect(() => {
    setEnabledLabel(user?.enabled || false);
    setEnabledSwitch(user?.enabled || false);
  }, [user]);

  const { currentTab, onChangeTab } = useTabs(tab ?? 'items');

  if(!id){
    return <Navigate to={PATH_APP.admin.users} />;
  }

  const Loader = <CircularProgress style={{ display: "block", margin: "0 auto" }} />

  const ACCOUNT_TABS = [
    {
      value: 'items',
      label: 'Items',
      icon: <Iconify icon={'icon-park-outline:heavy-metal'} width={20} height={20} />,
      component: !loading && user ? <Items user={user} /> : Loader,
    },
    {
      value: 'contact',
      label: 'Contact info',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: !loading && user ? <General user={user} /> : Loader,
    }
  ];

  const title = user ? `User: ${user.firstName} ${user.lastName}` : "User";

  const handleActivity = async (checked: boolean) => {
    if(working || !user || !id) return;
    setWorking(true);

    const oldState = enabledLabel;
    setEnabledSwitch(checked);

    try{
      const response = await axios.post("/admin/users/set-enabled", { id: user.id, enabled: checked });
      const newState = response.data.user.enabled;

      setTimeout(() => {
        setEnabledLabel(newState);
        setEnabledSwitch(newState);
        setWorking(false);
        enqueueSnackbar("User " + (newState ? "" : "de") + "activated.");
      }, 500);

      if(oldState != newState){
        dispatch(getUser(id));
      }
    }catch(e){
      setEnabledSwitch(oldState);
      setWorking(false);
    }
  };

  const Activity = user && <Stack direction={"column"} spacing={1} alignItems={"center"}>
    <Label
      variant={"ghost"}
      color={enabledLabel ? 'success' : 'error'}
      sx={{textTransform: 'capitalize'}}
    >{enabledLabel ? 'Active' : 'Inactive'}</Label>
      <Switch size={"medium"} checked={enabledSwitch} onChange={e => handleActivity(e.target.checked)} disabled={working}></Switch>
  </Stack>;

  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: '', href: PATH_APP.root }
          ]}
          action={Activity}
        >
        </HeaderBreadcrumbs>

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
