import { paramCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
// routes
import { PATH_APP } from '../../../routes/paths';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// @types
import { Item } from '../../../@types/item';
// components
import Label from '../../../components/Label';
import Image from '../../../components/Image';

// ----------------------------------------------------------------------

type Props = {
  product: Item;
};

export default function ItemCard({ product }: Props) {
  const { name, cover, price } = product;

  const linkTo = PATH_APP.general.viewItem(product);

  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
           <Link to={linkTo} color="inherit" component={RouterLink}>
          <Image alt={name} src={cover} ratio="1/1" />
        </Link>
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to={linkTo} color="inherit" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>
      </Stack>
    </Card>
  );
}
