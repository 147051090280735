import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment, Alert, Button, Switch, FormControlLabel, Link,
} from '@mui/material';
// @types
import { Item } from '../../@types/item';
// components
import {
  FormProvider, RHFCheckbox,
  RHFEditor, RHFSelect,
  RHFTextField,
  RHFUploadMultiFile,
} from '../../components/hook-form';
import {createItem, CreateItemPayload, editItem} from "../../redux/repos/ItemRepo";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import axios from "../../utils/axios";
import {PATH_APP, PATH_AUTH} from 'src/routes/paths';
import Iconify from "../../components/Iconify";
import CameraModal from "../../sections/CameraModal";
import {TierInfo} from "../../@types/subscription";
import useAuth from "../../hooks/useAuth";
import {dispatch, useSelector} from "../../redux/Store";
import {getCategories} from "../../redux/repos/CategoryRepo";
import Image from "../../components/Image";

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

interface FormValuesProps extends CreateItemPayload {
  afterSubmit?: string;
}

type Props = {
  isEdit: boolean;
  currentProduct?: Item;
};

export default function Form({ isEdit, currentProduct }: Props) {
  const navigate = useNavigate();
  const [ cameraOpen, setCameraOpen ] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  // const { enqueueSnackbar } = useSnackbar();

  const { data: catsData, error: catsError, loading: catsLoading } = useSelector((state) => state.catRepo);
  const cats = (!catsLoading && !catsError && catsData) ? catsData.items : [];

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Title is required'),
    description: Yup.string(),
    category: Yup.number(),
    info: Yup.object().shape({
      a: Yup.string().max(255, "255 characters max."),
      b: Yup.string().max(255, "255 characters max."),
      s: Yup.string().max(255, "255 characters max."),
      l: Yup.string().max(255, "255 characters max."),
      pcs: Yup.string().max(255, "255 characters max."),
    }),
    images: Yup.array().min(1, 'At least one image is required'),
    active: Yup.boolean()
  });

  const defaultValues = useMemo(
    () => ({
      name: currentProduct?.name || '',
      description: currentProduct?.description || '',
      category: currentProduct?.category?.id || 0,
      info: currentProduct?.info || undefined,
      images: currentProduct?.images || [],
      active: isEdit ? (currentProduct ? currentProduct.active : false) : true
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProduct]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const isMountedRef = useIsMountedRef();

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentProduct) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentProduct]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const images = values.images || [];

      setValue('images', [
        ...images,
        ...acceptedFiles.map((file: Blob | MediaSource) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values.images]
  );

  const { user } = useAuth();
  if(!user) return null;

  const ErrorMessages: (ReactNode | string)[] = [
    "Please check all fields.",
    <>You aren't logged in. <Link component={RouterLink} to={PATH_AUTH.login}>Log in</Link> to post new items.</>,
    <>You don't have an active subscription.</>,
    <>Trial accounts can't post items.</>,
    <>You can't post any more active items.</>,
    "Invalid category."
  ]

  const  onSubmit = async (data: FormValuesProps) => {
    if(isEdit && !currentProduct) return;

    let item: CreateItemPayload = {
      name: data?.name || "",
      description: (data.description && data.description != "<p><br></p>") ? data.description : undefined,
      category: data?.category || 0,
      info: data?.info || undefined,
      price: data?.price || 0,
      images: [],
      active: data ? data.active : false
    };

    try {
      const files = values.images || [];
      for (let file of files) {
        if(typeof file === "string" && file.startsWith("http")){
          item.images.push(file);
        }else{
          const formData = new FormData();
          formData.append("image", file);
          const response = await axios.post('/api/image', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
          item.images.push(response.data.id);
        }
      }
    } catch (error) {
      console.error(error);
      if(isMountedRef.current){
        let message = undefined;
        if(error?.code === undefined){
          if(error?.message){
            message = error.message;
          }else {
            message = ErrorMessages[0];
          }
        }else if(error?.code == 0){
          if(error?.message){
            message = error.message;
          }else{
            message = ErrorMessages[error.code];
          }
        }

        setError('afterSubmit', { ...error, message });
      }

      return;
    }

    try {
      const newItem = isEdit ? await editItem(currentProduct?.id || 0, item) : await createItem(item);
      if(isEdit){
        enqueueSnackbar("Item successfully updated.");
      }
      navigate(PATH_APP.general.viewItem(newItem));
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        let message = undefined;
        if(error?.code === undefined){
          if(error?.message){
            message = error.message;
          }else {
            message = ErrorMessages[0];
          }
        }else if(error?.code == 0){
          if(error?.message){
            message = error.message;
          }else{
            message = ErrorMessages[error.code];
          }
        }

        setError('afterSubmit', { ...error, message });
      }
    }
  };

  const handleRemove = (file: File | string) => {
    const filteredItems = values.images?.filter((_file) => _file !== file);
    setValue('images', filteredItems);
  };

  const handlePhoto = (photo: string) => {
    setCameraOpen(false);

    function DataURIToBlob(dataURI: string) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], { type: mimeString })
    }

    const blob = DataURIToBlob(photo) as any;

    const images = values.images || [];
    setValue('images', [
      ...images,
      Object.assign(blob, { preview: photo})
    ]);
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {!!errors.afterSubmit && <Alert severity="error" sx={{mb: 3}}>{errors.afterSubmit.message}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="name" label="Item Title" />

              <RHFSelect name="category" label="Category" placeholder="Category">
                <option value="0">Uncategorized</option>
                {cats.map((cat) => (
                  <option key={cat.id} value={cat.id} selected={cat.id == defaultValues.category}>
                    {cat.title}
                  </option>
                ))}
              </RHFSelect>

              { (values.category != 0) && <Grid container>
                <Grid item xs={4}>
                  <Stack spacing={1}>
                    <RHFTextField name="info.a" label="A" />
                    <RHFTextField name="info.b" label="B" />
                    <RHFTextField name="info.s" label="S" />
                    <RHFTextField name="info.l" label="L" />
                    <RHFTextField name="info.pcs" label="pcs" />
                  </Stack>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={7}>
                  { cats.filter(cat => cat.id == values.category).map(cat => <Image src={cat.image} />) }
                </Grid>
              </Grid> }

              <div>
                <LabelStyle>Description</LabelStyle>
                <RHFEditor simple name="description" />
              </div>

              <FormControlLabel
                labelPlacement="start"
                control={
                  <Controller
                    name="active"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(event) =>
                          field.onChange(event.target.checked)
                        }
                      />
                    )}
                  />
                }
                label={
                  <>
                    <LabelStyle>Active</LabelStyle>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Whether this item will appear in the marketplace
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ p: 3, mb: 3 }}>
            <Stack spacing={3} position={"relative"}>
              <div>
                <LabelStyle sx={{ mb: 6 }}>Images</LabelStyle>

                <Button variant={"outlined"} size={"large"} style={{width: 160, position: "absolute", right: 3, top: 3 }} endIcon={<Iconify icon={"ic:baseline-photo-camera"} />} onClick={() => setCameraOpen(true)}>Take photo</Button>

                <RHFUploadMultiFile
                  showPreview
                  name="images"
                  accept="image/*"
                  maxSize={4194304}
                  onDrop={handleDrop}
                  onRemove={handleRemove}
                  // onRemoveAll={handleRemoveAll}
                />
              </div>

              <CameraModal open={cameraOpen} close={() => setCameraOpen(false)} submit={handlePhoto} />
            </Stack>
          </Card>

          <LoadingButton type="submit" variant="contained" size="large" fullWidth loading={isSubmitting}>
            {!isEdit ? 'Create Item' : 'Save Changes'}
          </LoadingButton>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
