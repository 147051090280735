import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  pass1: string;
  pass2: string;
};

export default function ChangePassword() {
  const { enqueueSnackbar } = useSnackbar();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    pass1: Yup.string().min(8, 'Must be at least 8 characters').required('New password is required'),
    pass2: Yup.string().oneOf([Yup.ref('pass1'), null], 'Passwords must match'),
  });

  const defaultValues = {
    oldPassword: '',
    pass1: '',
    pass2: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await axios.post('/api/user/change_password', data);
      enqueueSnackbar('Password updated!');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField name="oldPassword" type="password" label="Old password" />

          <RHFTextField name="pass1" type="password" label="New password" />

          <RHFTextField name="pass2" type="password" label="Confirm new password" />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
