import {ReactNode} from "react";
import useAuth from "../hooks/useAuth";
import LoadingScreen from "../components/LoadingScreen";

interface Props {
  children: ReactNode
}

export default function AuthInitGuard({ children }: Props) {
  const { isInitialized } = useAuth();

  return isInitialized ? <>{children}</> : <LoadingScreen />;
}
