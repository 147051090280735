// @mui
import {Box, Stack, AppBar, Toolbar, Link, Button} from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import useAuth from "../../../hooks/useAuth";
import {Link as RouterLink} from "react-router-dom";
import {PATH_APP, PATH_AUTH, PATH_WEB} from "../../../routes/paths";
import {CSSProperties, ReactChild} from "react";
import {styled as muistyled, useTheme} from "@mui/material/styles";
import styled from "@emotion/styled";
import Iconify from "../../../components/Iconify";
import MobileMenu from "./MobileMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
// ----------------------------------------------------------------------

type RootStyleProps = {
  isOffset: boolean;
};

const RootStyle = muistyled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<RootStyleProps>(({ isOffset, theme }) => ({
  backgroundColor: "rgba(25,118,210,0.8)",
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `100%`,
    ...(isOffset && {
      // height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));

// ----------------------------------------------------------------------

const Items = [
  { title: "Homepage", link: PATH_WEB.home },
  { title: "Marketplace", link: PATH_APP.general.browse, internal: true },
  { title: "Pricing", link: PATH_WEB.pricing },
  { title: "Support", link: PATH_WEB.support },
  { title: "About us", link: PATH_WEB.about },
  { title: "Contact", link: PATH_WEB.contact },
];

export default function DashboardHeader() {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);

  const auth = useAuth();

  const MenuLink = (props: { to: string, children: ReactChild, internal?: boolean }) => {
    const style = {
      color: "#fff",
      fontWeight: 500,
      fontFamily: "'Roboto',Helvetica,Arial,Lucida,sans-serif",
      transition: "opacity .4s ease-in-out",
      textDecoration: "none",

      "&:hover": {
        opacity: 0.7
      }
    };

    const InternalLink = muistyled("a")(style);

    return props.internal
      ? <Link to={props.to} component={RouterLink} style={style}>{props.children}</Link>
      : <InternalLink href={props.to} style={style}>{props.children}</InternalLink>;
  }

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <RootStyle isOffset={isOffset}>
      <Toolbar variant={"regular"}
        sx={{
          maxWidth: "lg",
          width: "100%",
          margin: "0 auto",
          minHeight: '100% !important',
        }}
      >
        <Logo sx={{ mr: 2.5, position: "absolute", top: 0 }} />

        <Box sx={{ flexGrow: 1 }} />

        { mobile && <Stack direction={"row"} spacing={2}>
          { auth.isAuthenticated && <AccountPopover /> }
          <MobileMenu />
        </Stack> }

        { !mobile && <>
          <Stack direction="row" alignItems="center" spacing={"22px"}>
            { Items.map((item) => <MenuLink to={item.link} internal={item.internal}>{ item.title }</MenuLink>) }
          </Stack>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={"24px"}>
            {/*<LanguagePopover />
            <NotificationsPopover />
            <ContactsPopover />*/ }
            { auth.isAuthenticated ? <>
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill"/>} component={RouterLink} to={PATH_APP.general.newItem}>
                Post new item
              </Button>

              <AccountPopover />
            </> : <>
              <MenuLink to={PATH_AUTH.login} internal={true}>Log in</MenuLink>

              <Button variant={"contained"} sx={{ textTransform: "uppercase" }} component={RouterLink} to={PATH_AUTH.register}>
                Try it free
              </Button>
            </> }
          </Stack>
        </> }
      </Toolbar>
    </RootStyle>
  );
}
