import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { Item, ItemState } from '../../@types/item';
//
import { dispatch } from '../Store';
import {AdminSubscription} from "../../@types/subscription";
import {InitialRepoState, RepoReducers, RepoState} from "../Repo";
import {combineReducers} from "redux";

const slice = createSlice({
  name: 'sub-admin',
  initialState: { ...InitialRepoState, data: null } as RepoState<AdminSubscription>,
  reducers: RepoReducers<AdminSubscription>()
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAdminPending(page?: number) {
  if(!page){
    page = 1;
  }

  return async () => {
    dispatch(slice.actions.startLoading({ page }));

    try {
      const response = await axios.get('/api/sub/admin/pending');
      const pending = slice.actions.getManySuccess(response.data);
      dispatch(pending);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
