import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../Store';
import {InitialRepoState, RepoReducers, RepoState} from "../Repo";
import {User} from "../../@types/user";

const slice = createSlice({
  name: 'user',
  initialState: { ...InitialRepoState, data: null } as RepoState<User>,
  reducers: RepoReducers<User>(),
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers(page?: number, search?: string, subStatus?: boolean) {
  if(!page){
    page = 1;
  }

  return async () => {
    dispatch(slice.actions.startLoading({ page, search }));

    try {
      const params: any = { page };

      if(search && search.length != 0) params.query = search;
      if(subStatus != undefined) params.subStatus = subStatus ? "1" : "0";

      const response = search
        ? await axios.get('/admin/users/search', { params })
        : await axios.get('/admin/users', { params });

      const users = slice.actions.getManySuccess(response.data);
      dispatch(users);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getUser(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading({ page: 1 }));

    try {
      const response: { data: { item: User } } = await axios.get('/admin/users/' + id);
      dispatch(slice.actions.getSingleSuccess(response.data.item));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/*
export interface CreateItemPayload {
  name: string;
  description: string;
  price: number;
  images: string[]
}

export async function createItem(item: CreateItemPayload): Promise<Item> {
  const response = await axios.post('/api/items', item);
  return response.data.item;
}

export async function editItem(id: number, item: CreateItemPayload): Promise<Item> {
  const response = await axios.put('/api/items', { ...item, id });
  return response.data.item;
}
*/
