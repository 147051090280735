import {useEffect, useRef, useState} from 'react';
import { paramCase } from 'change-case';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {Link, Typography, Autocomplete, InputAdornment, Popper, PopperProps, CircularProgress} from '@mui/material';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// utils
import axios from '../../utils/axios';
// routes
import { PATH_APP } from '../../routes/paths';
// @types
import { Item } from '../../@types/item';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import InputStyle from '../../components/InputStyle';
import SearchNotFound from '../../components/SearchNotFound';

// ----------------------------------------------------------------------

const PopperStyle = styled((props: PopperProps) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

// ----------------------------------------------------------------------

interface Props {
  term?: string;
}

export default function Search({ term }: Props) {
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Item[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(inputRef && inputRef.current){
      inputRef.current.value = term || "";
    }

    handleChangeSearch(term || "");
  }, [term, inputRef]);

  const handleChangeSearch = async (value: string) => {
    setSearchResults([]);
    setSearchQuery(value);

    if(value == ""){
      return;
    }

    try {
      setLoading(true);

      const response = await axios.get('/api/items/search', {
        params: {query: value, perPage: 3},
      });

      if (isMountedRef.current) {
        const items: Item[] = response.data.items;
        setSearchResults(items.slice(0, 3));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleClick = (item: Item) => {
    navigate(PATH_APP.general.viewItem(item));
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if(inputRef && inputRef.current){
        inputRef.current.blur()
      }

      if(searchQuery == ""){
        navigate(PATH_APP.general.browse);
      }else{
        navigate(PATH_APP.general.search(encodeURIComponent(encodeURIComponent(searchQuery))));
      }
    }
  };

  const Loader = <CircularProgress style={{ display: "block", margin: "20px auto" }} />;

  return (
    <Autocomplete
      size="medium"
      autoHighlight={false}
      popupIcon={null}
      PopperComponent={PopperStyle}
      options={searchResults}
      onInputChange={(event, value) => handleChangeSearch(value)}
      getOptionLabel={(product: Item) => product.name + " " + product.description}
      noOptionsText={loading ? Loader : <SearchNotFound searchQuery={searchQuery} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      clearOnBlur={false}
      inputValue={searchQuery}
      fullWidth={true}
      renderInput={(params) => (
        <InputStyle
          {...params}
          placeholder="Search..."
          onKeyUp={handleKeyUp}
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, item: Item, { inputValue }) => {
        const { name, cover } = item;
        const matches = match(name, inputValue);
        const parts = parse(name, matches);

        return (
          <li {...props} key={`item-${item.id}`}>
            <Image
              alt={cover}
              src={cover}
              sx={{ width: 48, height: 48, borderRadius: 1, flexShrink: 0, mr: 1.5 }}
            />
            <Link underline="none" onClick={() => handleClick(item)}>
              {parts.map((part, index) => (
                <Typography
                  key={index}
                  component="span"
                  variant="subtitle2"
                  color={part.highlight ? 'primary' : 'textPrimary'}
                >
                  {part.text}
                </Typography>
              ))}
            </Link>
          </li>
        );
      }}
    />
  );
}
