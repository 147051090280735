import {Outlet} from 'react-router-dom';
// @mui
import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import {HEADER, NAVBAR} from '../../config';
//
import DashboardHeader from './header';

import Footer from './Footer'
import {useSelector} from "../../redux/Store";
import Error, {ErrorType} from "../../pages/Error";
// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  "> *:first-child": {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingTop: HEADER.MOBILE_HEIGHT + 60,
  width: "100%",
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 60,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    })
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { serverError } = useSelector(state => state.app);

  return (
    <Box sx={{ display: 'flex', minHeight: 1 }}>
      <DashboardHeader />

      <MainStyle>
        { serverError
          ? <Error type={ErrorType.Error500} />
          : <Outlet />
        }

        <Footer />
      </MainStyle>
    </Box>
  );
}
