import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {useCallback, useState} from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../hooks/useAuth';
// utils
import { fData } from '../../utils/formatNumber';
// _mock
import { countries } from '../../_mock';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../components/hook-form';
import axios from "../../utils/axios";
import {PATH_APP} from "../../routes/paths";
import InfoForm, {InfoFormValuesProps} from "../../sections/UserEdit/InfoForm";

// ----------------------------------------------------------------------

export default function General() {
  const { enqueueSnackbar } = useSnackbar();

  const [ submitting, setSubmitting ] = useState<boolean>(false);

  const { user, update } = useAuth();

  const onSubmit = async (data: InfoFormValuesProps) => {
    if(submitting) return;
    setSubmitting(true);

    try {
      const response = await axios.put('/api/user', data);
      enqueueSnackbar('Account updated!');
      await update(response.data.user);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }

    setSubmitting(false);
  };

  return (
    <InfoForm loading={false} submitting={submitting} user={user ? user : undefined} onSubmit={onSubmit} />
  );
}
