import {styled} from "@mui/material/styles";
import Page from "../../components/Page";
import {Button, Container, Typography} from "@mui/material";
import {MotionContainer, varBounce} from "../../components/animate";
import {m} from "framer-motion";
import SeverErrorIllustration from "../../assets/illustration_500";
import {PATH_APP} from "../../routes/paths";
import {Link as RouterLink} from "react-router-dom";
import Page404 from "./Page404";
import Page500 from "./Page500";

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
}));

export enum ErrorType {
  Error404,
  Error500
}

interface Props {
  type: ErrorType
}

const Titles: { [ key in ErrorType ]: string } = {
  [ErrorType.Error404]: "Not found",
  [ErrorType.Error500]: "Server error"
}

const Contents: { [ key in ErrorType ]: JSX.Element } = {
  [ErrorType.Error404]: <Page404 />,
  [ErrorType.Error500]: <Page500 />
}

export default function Error({ type }: Props){
  const title = Titles[type];
  const content = Contents[type];

  return (
    <Page title={title}>
      <Container component={MotionContainer} style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', flexGrow: 1 }}>
          { content }

          <Button href="/" size="large" variant="contained">
            Go to Marketplace
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
