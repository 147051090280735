import {useCallback, useEffect, useMemo, useState} from 'react';
import { paramCase } from 'change-case';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
// @mui
import {Box, Button, Card, CircularProgress, Container, Stack, Typography} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/Store';
import {getItem} from '../../redux/repos/ItemRepo';
// routes
import { PATH_APP } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import DeleteConfirmDialog from "../../sections/DeleteConfirmDialog";
import axios from "../../utils/axios";
import {useSnackbar} from "notistack";
import Iconify from "../../components/Iconify";
import {CategoryPayload, getCategory} from "../../redux/repos/CategoryRepo";
import {FormProvider, RHFSelect, RHFTextField, RHFUploadSingleFile} from "../../components/hook-form";
import {countries} from "../../_mock";
import {LoadingButton} from "@mui/lab";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {InfoFormValuesProps} from "../../sections/UserEdit/InfoForm";
import {styled} from "@mui/material/styles";
import {Category} from "../../@types/category";

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));


export type FormValuesProps = {
  title: string;
  slug: string;
  image: string;
};

export default function CatEditCreate() {
  const [ deleteOpen, setDeleteOpen ] = useState<boolean>(false);

  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [submitting, setSubmitting] = useState<boolean>(false);

  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = !pathname.includes('/categories/new');

  const { data, error, loading } = useSelector((state) => state.catRepo);
  const cat: Category | undefined = (isEdit && !loading && !error && data && data.items.length != 0) ? data.items[0] : undefined;

  const fetchCat = isEdit && id && !isNaN(+id);
  useEffect(() => {
    if(!fetchCat) return;
    dispatch(getCategory(id));
  }, [dispatch, id, fetchCat]);

  const deleteCat = async () => {
    try {
      const response = await axios.delete(`/api/cat/${id}`);
      enqueueSnackbar("Category deleted.");
      navigate(PATH_APP.admin.categories);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }
  };

  const UpdateCatSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    slug: Yup.string().required('Slug is required')
  });

  const defaultValues = useMemo(() => ({
    title: cat?.title || '',
    slug: cat?.slug || '',
    image: cat?.image || undefined,
  }), [cat]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateCatSchema),
    defaultValues,
  });

  const {
    setValue,
    trigger,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, cat]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if(acceptedFiles.length == 0) return;

      const image = Object.assign(acceptedFiles[0], {preview: URL.createObjectURL(acceptedFiles[0])});
      setValue('image', image);
    },
    [setValue, values.image]
  );

  if(loading){
    return <Card sx={{ p: 3 }}><CircularProgress sx={{ margin: "0 auto" }} /></Card>
  }

  const onSubmit = async (data: FormValuesProps) => {
    if(submitting) return;

    const success = await trigger();
    if(!success) return;

    setSubmitting(true);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("slug", data.slug);

    if(isEdit && id){
      formData.append("id", id);
    }

    if(data.image && !(typeof data.image === "string" && data.image.startsWith("http"))){
      formData.append("image", data.image);
    }

    try {
      const response = isEdit
        ? await axios.post('/api/cat/edit', formData)
        : await axios.post('/api/cat/create', formData);

      enqueueSnackbar(`Category ${isEdit ? "edited" : "created"}.`);
      navigate(PATH_APP.admin.categories);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }

    setSubmitting(false);
  }

  return (
    <Page title={!isEdit ? "New category" : "Edit category"}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? "New category" : "Edit category"}
          links={[{ name: '', href: PATH_APP.root }]}
          action={data && isEdit && <Button startIcon={<Iconify icon={"ic:baseline-delete"} />} variant="contained" onClick={() => setDeleteOpen(true)}>Delete category</Button>}
        />

        {data && isEdit && <DeleteConfirmDialog label={"category"} subtext={"All items under this category will be left uncategorized."} open={deleteOpen} onConfirm={deleteCat} onClose={() => setDeleteOpen(false)} /> }

        {error && <Typography variant="h6">404 Category not found</Typography>}

        { !error && (!loading || !isEdit) && <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={{
              p: 3,
              display: 'grid',
              rowGap: 3,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)' }
            }}>
                <Box
                    sx={{
                      display: 'grid',
                      rowGap: 3,
                      columnGap: 2,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                >
                    <Stack spacing={3} sx={{ pt: 4 }}>
                      <RHFTextField name="title" label="Title" />
                      <RHFTextField name="slug" label="Slug" />
                    </Stack>

                    <Stack>
                        <LabelStyle>Image</LabelStyle>
                        <RHFUploadSingleFile name="image" onDrop={handleDrop} accept="image/*" maxSize={3145728} />
                    </Stack>
                </Box>

                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton type="submit" variant="contained" loading={submitting}>
                        Save changes
                    </LoadingButton>
                </Stack>
            </Card>
        </FormProvider> }
      </Container>
    </Page>
  );
}
