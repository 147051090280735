import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../Store';
import {InitialRepoState, RepoReducers, RepoState} from "../Repo";
import {Category} from "../../@types/category";

const slice = createSlice({
  name: 'category',
  initialState: { ...InitialRepoState, data: null } as RepoState<Category>,
  reducers: RepoReducers<Category>(),
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCategories() {
  return async () => {
    dispatch(slice.actions.startLoading({  }));

    try {
      const response = await axios.get('/api/cat');
      const cats = slice.actions.getManySuccess(response.data);
      dispatch(cats);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCategory(id: string) {
  return async () => {
    dispatch(slice.actions.startLoading({ page: 1 }));

    try {
      const response: { data: { cat: Category } } = await axios.get('/api/cat/' + id);
      dispatch(slice.actions.getSingleSuccess(response.data.cat));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export interface CategoryPayload {
  id?: string;
  title: string;
  slug: string;
  image: string;
}

/*
export interface CreateItemPayload {
  name: string;
  description: string;
  price: number;
  images: string[]
}

export async function createItem(item: CreateItemPayload): Promise<Item> {
  const response = await axios.post('/api/items', item);
  return response.data.item;
}

export async function editItem(id: number, item: CreateItemPayload): Promise<Item> {
  const response = await axios.put('/api/items', { ...item, id });
  return response.data.item;
}
*/
