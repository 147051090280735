import {useEffect, useState} from 'react';
import { paramCase } from 'change-case';
import {useParams, useLocation, useNavigate} from 'react-router-dom';
// @mui
import {Button, Container, Typography} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/Store';
import {getItem} from '../../redux/repos/ItemRepo';
// routes
import { PATH_APP } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import Form from './Form';
import {bool} from "yup";
import DeleteConfirmDialog from "../../sections/DeleteConfirmDialog";
import axios from "../../utils/axios";
import {useSnackbar} from "notistack";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

export default function ItemEditCreate() {
  const [ deleteOpen, setDeleteOpen ] = useState<boolean>(false);

  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { pathname } = useLocation();
  const { id } = useParams();
  const isEdit = pathname.includes('/' +
    'edit-item/');

  const { data, error, loading } = useSelector((state) => state.itemRepo);
  const item = (isEdit && !loading && !error && data && data.items.length != 0) ? data.items[0] : undefined;

  const fetchItem = isEdit && id && !isNaN(+id);
  useEffect(() => {
    if(!fetchItem) return;
    dispatch(getItem(parseInt(id)));
  }, [dispatch, id, fetchItem]);

  const deleteItem = async () => {
    try {
      const response = await axios.delete(`/api/items/${id}`);
      enqueueSnackbar("Item deleted.");
      navigate(PATH_APP.general.myItems);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }
  };

  return (
    <Page title={!isEdit ? "New item" : "Edit item"}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'New item' : 'Edit item'}
          links={[{ name: '', href: PATH_APP.root }]}
          action={data && isEdit && <Button startIcon={<Iconify icon={"ic:baseline-delete"} />} variant="contained" onClick={() => setDeleteOpen(true)}>Delete item</Button>}
        />

        {data && isEdit && <DeleteConfirmDialog open={deleteOpen} onConfirm={deleteItem} onClose={() => setDeleteOpen(false)} /> }

        {error && <Typography variant="h6">404 Item not found</Typography>}

        { !error && (!loading || !isEdit) && <Form isEdit={isEdit} currentProduct={ isEdit && item ? item : undefined } /> }
      </Container>
    </Page>
  );
}
