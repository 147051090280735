import { useEffect, useState } from 'react';
// @mui
import {Container, Grid, Pagination, Stack, TextField} from '@mui/material';
// redux
import {dispatch, useDispatch, useSelector} from '../../redux/Store'
import { getItems } from '../../redux/repos/ItemRepo';
// routes
import { PATH_APP } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import Search from "./Search";
import List from "./List";
import {useNavigate, useParams} from "react-router-dom";
import SearchNotFound from "../../components/SearchNotFound";
import {RHFSelect} from "../../components/hook-form";
import {Theme, useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getCategories} from "../../redux/repos/CategoryRepo";
import EmptyContent from "../../components/EmptyContent";

// ----------------------------------------------------------------------

export default function Browse() {
  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const { category: catParam } = useParams();

  const dispatch = useDispatch();
  let { term } = useParams();
  if(term){
    term = decodeURIComponent(term);
  }

  const { data, page, loading, perPage, search } = useSelector((state) => state.itemRepo);
  const items = data?.items || [];
  const total = data?.total || 0;

  const { data: catsData, error: catsError, loading: catsLoading } = useSelector((state) => state.catRepo);
  const cats = (!catsLoading && !catsError && catsData) ? catsData.items : [];

  const [ category, setCategory ] = useState<string | undefined>(undefined);
  const catName = cats.filter(cat => cat.id.toString() == category)[0]?.title || "";

  const loadParamCategory = () => {
    const catId = cats.filter(cat => cat.slug == catParam)[0]?.id || undefined;
    if(catId === undefined){
      setCategory(undefined);
    }else{
      setCategory(catId.toString());
    }
  }

  useEffect(() => {
    dispatch(getCategories());
    if(catParam != undefined){
      loadParamCategory();
    }
  }, []);

  useEffect(() => {
    loadParamCategory();
  }, [cats, catParam]);

  useEffect(() => {
    if(catParam != undefined && category == undefined) return;
    if(catParam == undefined && term == undefined && category != undefined){
      setCategory(undefined);
      return;
    }

    dispatch(getItems(page, term, category));
  }, [dispatch, term, category]);

  const handlePageChange = (page: number) => {
    dispatch(getItems(page, search, category));
  }

  const handleCatChange = (newValue: string) => {
    const cat = newValue === "0" ? undefined : newValue;
    if(term != undefined){
      setCategory(cat);
    }else{
      if(cat == undefined){
        navigate(PATH_APP.general.browse);
        return;
      }

      const catObj = cats.filter(cat => cat.id.toString() == newValue)[0];
      if(catObj){
        navigate(PATH_APP.general.category(catObj));
      }
    }
  }

  const title = term ? `Search: ${term}` : "Browse";

  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  let NoneFoundError = <></>;
  if(!loading && items.length == 0){
    if(catParam != undefined && category == undefined){
      NoneFoundError = <>Unknown category.</>;
    }else{
      NoneFoundError = <>No items listed under <strong>{catName}</strong>.</>
    }
  }

  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: '', href: PATH_APP.root }
          ]}
        />

        <Stack spacing={4}>


          <Stack spacing={3} direction={ mobile ? "column" : "row" }>
              <TextField select SelectProps={{ native: true }} label={"Category"} sx={{minWidth: 240}} onChange={e => handleCatChange(e.target.value)}>
                <option value={"0"}>All</option>
                {cats.map(cat => <option value={cat.id} selected={cat.id.toString() == category}>{cat.title}</option>)}
              </TextField>

            <Search term={term} />
          </Stack>

          { (!loading && items.length == 0)
            ? (term
              ? <SearchNotFound searchQuery={term} />
              : <EmptyContent title={"No items found"} description={NoneFoundError} sx={{ '& span.MuiBox-root': { height: 160 } }} /> )
            : <List products={items} loading={loading} />
          }

          { (total > perPage) && <Pagination
              sx={{ ml: "auto" }}
              count={Math.ceil(total / perPage)}
              page={page || 1}
              onChange={(e, p) => handlePageChange(p)}
          /> }
        </Stack>

      </Container>
    </Page>
  );
}
