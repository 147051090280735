import {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {Box, Button, Card, Link, Typography,} from '@mui/material';
// redux
import {dispatch, useDispatch, useSelector} from '../redux/Store';
import {getMyItems, getUserItems} from '../redux/repos/ItemRepo';
// routes
import {PATH_APP, PATH_AUTH} from '../routes/paths';
// @types
import {Item} from '../@types/item';
// components
import Iconify from '../components/Iconify';
import Image from "../components/Image";
import Label from "../components/Label";
import UniTable from "../sections/UniTable";
import {User} from "../@types/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'name', label: 'Title', align: 'left'},
  {id: 'enabled', label: 'Status', align: 'center', width: 180},
  {id: ''},
];

// ----------------------------------------------------------------------

interface Props {
  user?: User;
}

export default function ItemsTable({ user }: Props) {

  const {data, loading, page, perPage} = useSelector((state) => state.itemRepo);
  const items = data?.items || [];
  const total = data?.total || 0;

  const handlePageChange = (page: number) => {
    dispatch(user ? getUserItems(user.id, page) : getMyItems(page));
  }

  useEffect(() => {
    dispatch(user ? getUserItems(user.id) : getMyItems());
  }, [user, dispatch]);

  const mapFunc = (item: Item, index: number) => {
    const linkView = PATH_APP.general.viewItem(item);
    const linkEdit = PATH_APP.general.editItem(item);

    return [
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link to={linkView} component={RouterLink}>
          <Image
            disabledEffect
            alt={item.name}
            src={item.cover}
            sx={{borderRadius: 1.5, width: 48, height: 48, mr: 2}}
          />
        </Link>
        <Link to={linkView} component={RouterLink}>
          <Typography variant="subtitle2" noWrap sx={{ maxWidth: { lg: 800, md: 600, xs: 200 } }}>
            {item.name}
          </Typography>
        </Link>
      </Box>,

      <Typography align={"center"}>
        <Label variant={true ? 'ghost' : 'filled'} color={ item.active ? 'success' : 'error' } sx={{ textTransform: 'capitalize' }}>
          {item.active ? "Active" : "Inactive"}
        </Label>
      </Typography>,

      <Typography align={"right"}>
        <Button to={linkEdit} component={RouterLink}><Iconify icon={'eva:edit-fill'} />Edit</Button>
      </Typography>
    ];
  };

  return <UniTable labels={TABLE_HEAD} items={items} isLoading={loading} rowMap={mapFunc} emptyText={"No items posted"}
              page={page} total={total} perPage={perPage} onChange={handlePageChange}/>
}
