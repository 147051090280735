import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {combineReducers} from "redux";
import itemReducer from "./repos/ItemRepo";
import subReducer from "./repos/SubRepo";
import adminSubReducer from "./repos/AdminSubRepo";
import userReducer from "./repos/UserRepo";
import catReducer from "./repos/CategoryRepo";
import appReducer from "./AppState";
import {setupInterceptors} from "../utils/axios";

// ----------------------------------------------------------------------

const RootReducer = combineReducers({
  itemRepo: itemReducer,
  subRepo: subReducer,
  adminSubRepo: adminSubReducer,
  userRepo: userReducer,
  catRepo: catReducer,
  app: appReducer
});

const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export type RootState = ReturnType<typeof RootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, dispatch, useSelector, useDispatch };

setupInterceptors(store);
