import {Box, Button, Dialog, DialogTitle, Grid, IconButton, List, ListItem} from "@mui/material";
import AspectRatioBox from "./AspectRatioBox";
import {useEffect, useRef, useState} from "react";
import {Camera} from "react-camera-pro";
import {styled} from "@mui/material/styles";
import Iconify from "../components/Iconify";
import {Link as RouterLink} from "react-router-dom";
import {PATH_APP} from "../routes/paths";
import Image from "../components/Image"

interface Props {
  open: boolean;
  close: () => void;
  submit: (image: string) => void;
}

const ButtonContainer = styled("div")({
  padding: "20px 0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around"
});

// Hook
function usePrevious<T>(value: T){
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}

export default function CameraModal(props: Props){
  const { open, close, submit } = props;
  const prevOpen = usePrevious<boolean>(open);

  const camera = useRef<typeof Camera>(null);
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    if(open && !prevOpen){
      setImage(null);
    }
  }, [open]);

  const handlePhoto = () => {
    if(camera.current == undefined) return;
    // @ts-ignore
    const img = camera.current.takePhoto();
    setImage(img);
  }

  const handleRetry = () => {
    setImage(null);
  }

  const handleSubmit = () => {
    if(image == null){
      close();
    }else{
      submit(image);
    }
  }

  const handleClose = () => {
    setImage(null);
  }

  return (
    <Dialog open={open} onClose={close} fullWidth={true} maxWidth={"sm"}>
      <AspectRatioBox ratio={1}>
        { image == null
          ? <Camera ref={camera} aspectRatio={1} facingMode={"environment"} errorMessages={{}} />
          : <Image src={image} />
        }
      </AspectRatioBox>
      <ButtonContainer>
        { image == null
          ? <IconButton onClick={handlePhoto} size={"large"} style={{ border: "3px solid #eee" }}><Iconify icon={"ic:baseline-photo-camera"} width={24} height={24} /></IconButton>
          : <>
            <IconButton onClick={handleRetry} size={"large"} style={{border: "3px solid #eee"}}><Iconify icon={"ic:baseline-delete"} width={24} height={24}/></IconButton>
            <IconButton onClick={handleSubmit} size={"large"} style={{border: "3px solid #eee"}}><Iconify icon={"ic:baseline-check"} width={24} height={24}/></IconButton>
          </>
        }
      </ButtonContainer>
    </Dialog>
  );
}
