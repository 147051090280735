// @mui
import { useTheme } from '@mui/material/styles';
import {TableRow, Checkbox, TableCell, Typography, MenuItem, Link, Button} from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  cells: any[]
};

export default function Row(props: Props) {
  const { cells } = props;

  return (
    <TableRow hover>
      { cells.map(cell => <TableCell>{cell}</TableCell>) }
    </TableRow>
  );
}
