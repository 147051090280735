import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// routes
import { PATH_APP } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// _mock_
import { _userPayment, _userAddressBook, _userInvoices, _userAbout } from '../../_mock';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import {useNavigate, useParams} from "react-router-dom";
import Settings from "./Settings";
import Categories from "./Categories";
import {useEffect} from "react";

// ----------------------------------------------------------------------

interface Props {
  tab?: string;
}

export default function AdminArea({ tab }: Props) {
  const { themeStretch } = useSettings();

  const { currentTab, setCurrentTab } = useTabs('settings');

  useEffect(() => {
    if(tab && ["categories"].indexOf(tab) != -1){
      setCurrentTab(tab);
    }else{
      setCurrentTab("settings");
    }
  }, [tab]);

  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    if(newValue == "settings"){
      navigate(PATH_APP.admin.root);
    }else{
      navigate(PATH_APP.admin.root + newValue);
    }
  }

  const ADMIN_TABS = [
    {
      value: 'settings',
      label: 'Settings',
      icon: <Iconify icon={'fa-solid:cog'} width={20} height={20}/>,
      component: (
        <Settings/>
      )
    },
    {
      value: 'categories',
      label: 'Categories',
      icon: <Iconify icon={'dashicons:category'} width={20} height={20} />,
      component: (
        <Categories />
      )
    }
  ];

  return (
    <Page title="Admin area">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Admin area"
          links={[
            { name: '', href: PATH_APP.root }
          ]}
        />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={handleTabChange}
        >
          {ADMIN_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ADMIN_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
