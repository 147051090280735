import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard from "../guards/GuestGuard";
import useAuth from "../hooks/useAuth";
import AuthGuard from "../guards/AuthGuard";
import { PATH_AFTER_LOGIN } from "../config";
import Browse from "../pages/Browse";
import ItemEditCreate from "../pages/ItemEditCreate";
import UserAccount from "../pages/UserAccount";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import {PATH_APP} from "./paths";
import AdminArea from "../pages/AdminArea";
import ItemView from "../pages/ItemView";
import MyItems from "../pages/MyItems";
import {ErrorType} from "../pages/Error";
import AdminUsers from "../pages/AdminUsers";
import AdminUser from "../pages/AdminUsers/User";
import UserCreate from "../pages/AdminUsers/UserCreate";
import CatEditCreate from "../pages/AdminArea/CatEditCreate";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/browse" replace />,
      index: true
    },

    {
      path: '/',
      element: <DashboardLayout/>,
      children: [
        { path: 'browse/:category', element: <Browse /> },
        { path: 'browse', element: <Browse /> },
        { path: 'search/:term', element: <Browse /> },
        { path: 'item/:id/:title', element: <ItemView /> }
      ],
    },

    {
      path: '/',
      element: <GuestGuard><DashboardLayout /></GuestGuard>,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'reset-password/:email/:uid', element: <NewPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        /*{ path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },*/
      ],
    },

    {
      path: '/',
      element:
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>,
      children: [
        { index: true, element: <Navigate to={PATH_AFTER_LOGIN} replace /> },
        { path: 'my-items', element: <MyItems /> },
        { path: 'new-item', element: <ItemEditCreate /> },
        { path: 'edit-item/:id', element: <ItemEditCreate /> },
        { path: 'account/:tab', element: <UserAccount /> },
        { path: 'account', element: <UserAccount /> },
        /*{
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true }
          ],
        },*/
      ],
    },

    {
      path: '/admin',
      element:
        <AuthGuard>
          <RoleBasedGuard roles={["ROLE_ADMIN"]}>
            <DashboardLayout/>
          </RoleBasedGuard>
        </AuthGuard>,
      children: [
        { index: true, element: <AdminArea /> },
        { path: "settings", element: <AdminArea tab={"settings"} /> },
        { path: "categories", element: <AdminArea tab={"categories"} /> },
        { path: "users", element: <AdminUsers /> },
        { path: "users/new", element: <UserCreate /> },
        { path: "user/:id", element: <AdminUser /> },
        { path: "categories/new", element: <CatEditCreate /> },
        { path: "category/:id", element: <CatEditCreate /> }
        /*{
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true }
          ],
        },*/
      ],
    },

    {
      path: '*',
      element: <DashboardLayout />,
      children: [
        { path: '404', element: <Error type={ErrorType.Error404} /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const Error = Loadable(lazy(() => import('../pages/Error')));
