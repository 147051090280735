
interface RepoStateCommon {
  loading: boolean;
  error: Error | string | null;
  search: string;
  page: number;
  perPage: number;
}

interface RepoStateData<T> {
  items: T[];
  total: number;
}

export interface RepoState<T> extends RepoStateCommon {
  data: RepoStateData<T> | null;
}

export const InitialRepoState: RepoStateCommon = {
  loading: false,
  error: null,
  search: "",
  page: 1,
  perPage: 10
}

export const RepoReducers = <T>() => {
  return {
    startLoading(state: RepoState<T>, action: { payload: any, type: string }) {
      state = {
        ...state,
        error: null,
        data: null,
        loading: true,
        search: action.payload.search || "",
        page: action.payload.page || 1
      }

      return state;
    },

    // HAS ERROR
    hasError(state: RepoState<T>, action: { payload: any, type: string }) {
      state = {
        ...state,
        error: action.payload,
        data: null,
        loading: false
      }

      return state;
    },

    // GET PRODUCTS
    getManySuccess(state: RepoState<T>, action: { payload: any, type: string }) {
      state = {
        ...state,
        error: null,
        loading: false,
        page: action.payload.page,
        perPage: action.payload.perPage,
        data: {
          total: action.payload.total,
          items: action.payload.items
        }
      }

      return state;
    },

    // GET PRODUCT
    getSingleSuccess(state: RepoState<T>, action: { payload: any, type: string }) {
      state = {
        ...state,
        error: null,
        loading: false,
        page: 1,
        perPage: 1,
        data: {
          total: 1,
          items: action.payload ? [ action.payload ] : []
        }
      }

      return state;
    }
  }
}
