import { sentenceCase } from 'change-case';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// form
import { Controller, useForm } from 'react-hook-form';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  Card, Grid
} from '@mui/material';
// routes
import {PATH_APP, PATH_AUTH} from '../../../routes/paths';
// @types
import {Item, ItemInfo} from '../../../@types/item';
import Markdown from "../../../components/Markdown";
import useAuth from "../../../hooks/useAuth";
import {useState} from "react";
import SellerInfoDialog from "./SellerInfoDialog";
import DeleteConfirmDialog from "../../../sections/DeleteConfirmDialog";
import axios from "../../../utils/axios";
import {useSnackbar} from "notistack";
import {AuthUser} from "../../../@types/auth";
import Image from "../../../components/Image";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({

}));

// ----------------------------------------------------------------------

type Props = {
  product: Item;
};

export default function Details({
  product,
  ...other
}: Props) {
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const auth = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const deleteItem = async () => {
    try {
      const response = await axios.delete(`/api/items/${product.id}`);
      enqueueSnackbar("Item deleted.");
      navigate(PATH_APP.general.browse);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }
  };

  const user: AuthUser = auth.user;
  const admin = user ? user.roles.indexOf("ROLE_ADMIN") != -1 : false;

  return (
    <RootStyle {...other}>
      <Stack spacing={3}>
        { product.description && product.description != "" && <Markdown children={product.description} /> }

        { product.category && product.category.id != 0 && <Card>
          <Box sx={{ p: 2, bgcolor: 'background.neutral' }}>
            <Typography variant={"subtitle1"} display={"inline-block"}>{product.category.title}</Typography>
            { product.info && <Typography variant={"subtitle2"} display={"inline-block"} align={"right"} sx={{float: "right", paddingTop: "2px"}}>dimensions</Typography> }
          </Box>

          { product.info && <>
          <Divider />

          <Box sx={{ p: 3 }}>
            <Grid container>
                <Grid item xs={4}>
                    <table>
                      { Object.keys(product.info).map((key: string) => <tr>
                        <th style={{ minWidth: 50 }}>{key.toUpperCase()}:</th><th style={{ fontWeight: "normal" }}>{ product.info && product.info.hasOwnProperty(key) && product.info[key as keyof ItemInfo] }</th>
                      </tr>) }
                    </table>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={7}>
                  { product?.category?.image && <Image src={product.category.image} /> }
                </Grid>
            </Grid>
          </Box> </> }
        </Card> }

        <Stack direction="row" spacing={2}>
          { auth.isAuthenticated
            ? <Button fullWidth size="large" variant="contained" style={{ textTransform: "none" }} onClick={() => setInfoOpen(true)}>Contact seller</Button>
            : <Button fullWidth size="large" variant="contained" to={PATH_AUTH.login} component={RouterLink} style={{ textTransform: "none" }}>Log in to view seller info</Button> }
        </Stack>

        {auth.isAuthenticated && (product?.editable || admin) && <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button fullWidth size="large" variant="contained" component={RouterLink} to={PATH_APP.general.editItem(product)}>
                Edit
            </Button>
            <Button fullWidth size="large" variant="contained" onClick={() => setDeleteOpen(true)}>
                Delete
            </Button>

            <DeleteConfirmDialog open={deleteOpen} onConfirm={deleteItem} onClose={() => setDeleteOpen(false)} />
        </Stack>}

      </Stack>

      <SellerInfoDialog product={product} open={infoOpen} onClose={() => setInfoOpen(false)} />

      {/*<Stack alignItems="center" sx={{ mt: 3 }}>
        <SocialsButton initialColor />
      </Stack>*/}
    </RootStyle>
  );
}
