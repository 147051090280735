import {InputAdornment, MenuItem, Stack, TextField} from "@mui/material";
import Iconify from "../components/Iconify";

type Props = {
  filterLabel?: string;
  filterValues?: string[];
  filterSelected?: string;
  search: string;
  onSearch: (value: string) => void;
  onFilter: (event: string) => void;
};

export default function TableFilter({
                                      filterLabel,
                                      filterValues,
                                      filterSelected,
                                      search,
                                      onSearch,
                                      onFilter,
                                    }: Props) {
  return (
    <Stack spacing={2} direction={{xs: 'column', sm: 'row'}} sx={{py: 2.5, px: 3}}>
      { filterValues &&  <TextField
        fullWidth
        select
        label={filterLabel}
        value={filterSelected}
        onChange={e => onFilter(e.target.value)}
        SelectProps={{
          MenuProps: {
            sx: {'& .MuiPaper-root': {maxHeight: 260}},
          },
        }}
        sx={{
          maxWidth: {sm: 240},
          textTransform: 'capitalize',
        }}
      >
        {filterValues.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField> }

      <TextField
        fullWidth
        value={search}
        onChange={(event) => onSearch(event.target.value)}
        placeholder="Search..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{color: 'text.disabled', width: 20, height: 20}}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
