import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs } from '@mui/material';
// routes
import { PATH_APP } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import {useParams} from "react-router-dom";
import General from "./General";
import ChangePassword from "./ChangePassword";

// ----------------------------------------------------------------------

export default function UserAccount() {
  const { themeStretch } = useSettings();

  let { tab } = useParams();
  if(tab && ["change-password"].indexOf(tab) == -1){
    tab = undefined;
  }

  const { currentTab, onChangeTab } = useTabs(tab ?? 'contact');

  const ACCOUNT_TABS = [
    {
      value: 'contact',
      label: 'Contact info',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <General />,
    },
    {
      value: 'change-password',
      label: 'Change password',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: <ChangePassword />,
    },
  ];

  return (
    <Page title="Account settings">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Account settings"
          links={[
            { name: '', href: PATH_APP.root }
          ]}
        />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
