import {Link as RouterLink, Navigate} from 'react-router-dom';
// @mui
import {Button, Card, Container} from '@mui/material';
// routes
import {PATH_APP, PATH_AUTH} from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import useAuth from "../../hooks/useAuth";
import {TierInfo} from "../../@types/subscription";
import ItemsTable from "../../sections/ItemsTable";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'name', label: 'Title', align: 'left'},
  {id: 'enabled', label: 'Status', align: 'center', width: 180},
  {id: ''},
];

// ----------------------------------------------------------------------

export default function MyItems() {
  const {themeStretch} = useSettings();

  const { user } = useAuth();
  if(!user) return <Navigate to={PATH_AUTH.login} />

  const sub = user.sub;

  return (
    <Page title="My items">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="My items"
          links={[
            {name: '', href: PATH_APP.root}
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill"/>}
              component={RouterLink}
              to={PATH_APP.general.newItem}
            >
              Post new item
            </Button>
          }
        />

        <Card>
          <ItemsTable />
        </Card>
      </Container>
    </Page>
  );
}
