import {useEffect, useState} from 'react';
// @mui
import {Box, Grid, Card, Stack, CircularProgress, Link, Typography, Button} from '@mui/material';
import {useSnackbar} from "notistack";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import RHFTextArea from "../../../components/hook-form/RHFTextArea";
import axios from "../../../utils/axios";
import {LoadingButton} from "@mui/lab";
import {dispatch, useSelector} from "../../../redux/Store";
import {getMyItems, getUserItems} from "../../../redux/repos/ItemRepo";
import {getCategories} from "../../../redux/repos/CategoryRepo";
import UniTable from "../../../sections/UniTable";
import {Item} from "../../../@types/item";
import {PATH_APP} from "../../../routes/paths";
import {Link as RouterLink} from "react-router-dom";
import Image from "../../../components/Image";
import Label from "../../../components/Label";
import Iconify from "../../../components/Iconify";
import {Category} from "../../../@types/category";


// ----------------------------------------------------------------------

type Props = {

};

const TABLE_HEAD = [
  {id: 'image', label: '', align: 'left', width: 80},
  {id: 'title', label: 'Title', align: 'left'},
  {id: 'slug', label: 'Slug', align: 'left', width: 180},
  {id: ''},
];


export default function Categories({ }: Props) {
  const {data, loading} = useSelector((state) => state.catRepo);
  const cats = data?.items || [];

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const mapFunc = (item: Category, index: number) => {
    const linkEdit = PATH_APP.admin.catEdit(String(item.id));

    return [
      item.image ? <Image
        disabledEffect
        src={item.image}
        sx={{borderRadius: 1.5, width: 48, height: 48, mr: 2}}
      /> : <></>,

      <Typography variant="subtitle2" noWrap sx={{ maxWidth: { lg: 800, md: 600, xs: 200 } }}>
        {item.title}
      </Typography>,

      <Typography align={"left"}>
        {item.slug}
      </Typography>,

      <Typography align={"right"}>
        <Button to={linkEdit} component={RouterLink}><Iconify icon={'eva:edit-fill'} />Edit</Button>
      </Typography>
    ];
  };

  const Content = <UniTable<Category> labels={TABLE_HEAD} items={cats} isLoading={loading} rowMap={mapFunc} emptyText={"No categories"}
                            page={1} total={cats.length} perPage={cats.length} onChange={() => {}} size={"small"}/>

  const Loader = <CircularProgress style={{ display: "block", margin: "0 auto" }} />;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography align={"left"}>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill"/>}
            component={RouterLink}
            to={PATH_APP.admin.catCreate}
          >Add new category</Button>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          { loading ? Loader : Content }
        </Card>
      </Grid>
    </Grid>
  );
}
