// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import {SnackbarProvider} from "notistack";
import AuthInitGuard from "./guards/AuthInitGuard";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <SnackbarProvider>
          <AuthInitGuard>
            <ProgressBarStyle />
            <ScrollToTop />
            <Router />
          </AuthInitGuard>
        </SnackbarProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
