import { Paper, PaperProps, Typography } from '@mui/material';
import EmptyContent from "./EmptyContent";

// ----------------------------------------------------------------------

interface Props extends PaperProps {
  searchQuery?: string;
}

export default function SearchNotFound({ searchQuery = '', ...other }: Props) {
  const description = searchQuery
    ? <>No results found for <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using complete words.</>
    : <>Please enter search term.</>;

  return (
    <Paper {...other}>
      <EmptyContent title={"No items found"} description={description} sx={{'& span.MuiBox-root': {height: 160}}}/>
    </Paper>
  );
}
