import {m} from 'framer-motion';
import {Typography} from "@mui/material";
import {varBounce} from "../../components/animate";
import SeverErrorIllustration from "../../assets/illustration_500";

export default function Page500() {
  return <>
    <m.div variants={varBounce().in}>
      <Typography variant="h3" paragraph>
        Internal Server Error
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <Typography sx={{color: 'text.secondary'}}>
        There was an error, please try again later.
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <SeverErrorIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
    </m.div>
  </>
}
