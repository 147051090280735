import {Link as RouterLink, useParams} from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import {Card, Grid, Container, Typography, Alert, Link} from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/Store';
import { getItem } from '../../redux/repos/ItemRepo';
// routes
import { PATH_APP } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import { SkeletonProduct } from '../../components/skeleton';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Images from "./Images";
import Details from "./Details";


// ----------------------------------------------------------------------

export default function ItemView() {
  const { themeStretch } = useSettings();

  const dispatch = useDispatch();

  const params = useParams();

  const id: number = parseInt(params?.id || "0");
  const title: string = params?.title || "";

  const { data, error, loading } = useSelector((state) => state.itemRepo);
  const item = (!error && data && data.items.length != 0) ? data.items[0] : undefined;
  const name = item?.name || "";
  const price = item?.price || 0;

  useEffect(() => {
    dispatch(getItem(id));
  }, [dispatch, id]);

  const links = [ { name: 'Marketplace', href: PATH_APP.general.browse } ];
  if(item?.category){
    const cat = item.category;
    links.push({ name: cat.title, href: PATH_APP.general.category(cat) });
  }

  return (
    <Page title={name}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={name}
          links={links}
          activeLast={true}
          /*action={ product &&
            <Typography variant="h4" sx={{ mb: 3 }}>
              {fCurrency(price)}
            </Typography>
          }*/
        />

        { item && !item.active && <Alert severity="warning" sx={{mb: 3}}>This item is inactive and will not appear in the marketplace. <Link component={RouterLink} to={PATH_APP.general.editItem(item)}>Edit the item</Link> to activate it.</Alert> }

        {item && (
          <>
            <Grid container sx={{ padding: 2 }} spacing={3}>
              <Grid item xs={12} md={6} lg={7}>
                <Images product={item} />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <Details product={item} />
              </Grid>
            </Grid>
          </>
        )}

        {loading && <SkeletonProduct />}

        {error && <Typography variant="h6">404 Item not found</Typography>}
      </Container>
    </Page>
  );
}
