import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
import {Item, ItemInfo} from '../../@types/item';
//
import { dispatch } from '../Store';
import {InitialRepoState, RepoReducers, RepoState} from "../Repo";

// ----------------------------------------------------------------------

type ItemRepoState = RepoState<Item>;

const InitialState: ItemRepoState = { ...InitialRepoState, data: null };

const slice = createSlice({
  name: 'item',
  initialState: InitialState,
  reducers: RepoReducers<Item>(),
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getItems(page?: number, search?: string, category?: string) {
  if(!page){
    page = 1;
  }

  return async () => {
    dispatch(slice.actions.startLoading({ page, search }));

    try {
      const response = search
        ? await axios.get('/api/items/search', { params: { page, query: search, cat: category } })
        : await axios.get('/api/items/browse', { params: { page, cat: category } });

      const items = slice.actions.getManySuccess(response.data);
      dispatch(items);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyItems(page?: number) {
  if(!page){
    page = 1;
  }

  return async () => {
    dispatch(slice.actions.startLoading({ page }));

    try {
      const response = await axios.get('/api/user/items?page=' + page);
      const items = slice.actions.getManySuccess(response.data);
      dispatch(items);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserItems(user: string, page?: number) {
  if(!page){
    page = 1;
  }

  return async () => {
    dispatch(slice.actions.startLoading({ page }));

    try {
      const response = await axios.get(`/api/items/?user=${user}&page=${page}`);
      const items = slice.actions.getManySuccess(response.data);
      dispatch(items);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getItem(id: number) {
  return async () => {
    dispatch(slice.actions.startLoading({ page: 1 }));

    try {
      const response: { data: { item: Item } } = await axios.get('/api/items/' + id);
      dispatch(slice.actions.getSingleSuccess(response.data.item));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export interface CreateItemPayload {
  name: string;
  description?: string;
  category: number;
  info?: ItemInfo;
  price: number;
  images: string[];
  active: boolean;
}

export async function createItem(item: CreateItemPayload): Promise<Item> {
  const response = await axios.post('/api/items', item);
  return response.data.item;
}

export async function editItem(id: number, item: CreateItemPayload): Promise<Item> {
  const response = await axios.put('/api/items', { ...item, id });
  return response.data.item;
}
