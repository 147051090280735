import axios from 'axios';
// config
import { HOST_API } from '../config';
import {clearError, setError} from "../redux/AppState";
import {Store} from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API
});

axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

export function setupInterceptors(store: Store){
  axiosInstance.interceptors.response.use(
    (response) => {
      store.dispatch(clearError());
      return response;
    },
    (error) => {
      if(!error.response || error.response.status == 500){
        store.dispatch(setError());
        return Promise.reject("Server error");
      }else{
        store.dispatch(clearError());
        return Promise.reject(error.response.data);
      }
    }
  );
}

export default axiosInstance;
