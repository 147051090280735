import {Item, SellerInfo} from "../../../@types/item";
import {Dialog, DialogTitle, List, ListItem} from "@mui/material";
import {countries} from "../../../_mock";


type Props = {
  product: Item;
  open: boolean;
  onClose: () => void;
};

export default function SellerInfoDialog(props: Props) {
  const { open, onClose, product } = props;
  const info = product.sellerInfo;

  if(info == undefined){
    return null;
  }

  const data = [
    info.phoneNumber,
    info.address,
    `${info.postCode} ${info.city}`,
    countries.find(c => c.code == info.country)?.label || ""
  ];

  if(info.companyName != ""){
    data.push(info.companyName);
  }

  data.push(info.email);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>{info.name}</DialogTitle>
      <List>
        {data.map(info => <ListItem>{info}</ListItem>)}
      </List>
    </Dialog>
  );
}
