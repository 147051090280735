import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Divider,
  Stack,
  Typography
} from "@mui/material";


type Props = {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  label?: string;
  subtext?: string;
};

export default function DeleteConfirmDialog(props: Props) {
  const { open, onClose, onConfirm, label = "item", subtext } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle sx={{pb:3}}>Delete {label}?</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <DialogContentText>Are you sure you want to delete this {label}? This action is irreversible.</DialogContentText>
          { subtext && <DialogContentText>{subtext}</DialogContentText> }
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color={"error"} onClick={onConfirm} variant="contained">Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
