// @mui
import {TableRow, TableCell, CircularProgress} from '@mui/material';
//
import EmptyContent from '../EmptyContent';

// ----------------------------------------------------------------------

type Props = {
  isNotFound?: boolean;
  notFoundText?: string;
};

export default function TableNoData({ isNotFound, notFoundText }: Props) {
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title={notFoundText || "No Data"}
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} align={"center"}>
          <CircularProgress />
        </TableCell>
      )}
    </TableRow>
  );
}
