import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

import { dispatch } from '../Store';
import {Subscription} from "../../@types/subscription";
import {InitialRepoState, RepoReducers, RepoState} from "../Repo";
import {combineReducers} from "redux";

const sliceHistory = createSlice({
  name: 'sub-history',
  initialState: { ...InitialRepoState, data: null } as RepoState<Subscription>,
  reducers: RepoReducers<Subscription>()
});

const slicePending = createSlice({
  name: 'sub-pending',
  initialState: { ...InitialRepoState, data: null } as RepoState<Subscription>,
  reducers: RepoReducers<Subscription>()
});

const sliceActive = createSlice({
  name: "sub-active",
  initialState: { ...InitialRepoState, data: null } as RepoState<Subscription>,
  reducers: RepoReducers<Subscription>()
});

// Reducer
export default combineReducers({
  history: sliceHistory.reducer,
  pending: slicePending.reducer,
  active: sliceActive.reducer,
});

// ----------------------------------------------------------------------

export function getActive(user?: string) {
  return async () => {
    dispatch(sliceActive.actions.startLoading({ page: 1 }));

    try {
      const response: { data: { active: Subscription | null } } = await axios.get('/api/sub/active', user ? { params: { user } } : undefined);
      const active = sliceActive.actions.getSingleSuccess(response.data.active);
      dispatch(active);
    } catch (error) {
      dispatch(sliceActive.actions.hasError(error));
    }
  };
}

export function getHistory(page?: number, user?: string) {
  if(!page){
    page = 1;
  }

  return async () => {
    dispatch(sliceHistory.actions.startLoading({ page }));

    try {
      const response = await axios.get('/api/sub/history', user ? { params: { user } } : undefined);
      const history = sliceHistory.actions.getManySuccess(response.data);
      dispatch(history);
    } catch (error) {
      dispatch(sliceHistory.actions.hasError(error));
    }
  };
}

export function getPending(page?: number, user?: string) {
  if(!page){
    page = 1;
  }

  return async () => {
    dispatch(slicePending.actions.startLoading({ page }));

    try {
      const response = await axios.get('/api/sub/pending', user ? { params: { user } } : undefined);
      const pending = slicePending.actions.getManySuccess(response.data);
      dispatch(pending);
    } catch (error) {
      dispatch(slicePending.actions.hasError(error));
    }
  };
}
