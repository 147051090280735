import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { PATH_AFTER_LOGIN } from "../config";

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  noBg?: boolean
}

export default function Logo({ disabledLink = false, noBg = false, sx }: Props) {

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width: 101, height: 96, ...sx }}>
      <svg width="90%" height="90%" viewBox="0 0 101 96" xmlns="http://www.w3.org/2000/svg" filter={noBg ? undefined : "drop-shadow(4px 4px 8px rgb(0 0 0 / 0.3))"}>
        <linearGradient id="a" gradientTransform="matrix(1 0 0 -1 0 114)" gradientUnits="userSpaceOnUse" x1="70.555077" x2="101.41237" y1="77" y2="77">
          <stop offset="0" stop-color="#00468c"/>
          <stop offset="1" stop-color="#0af"/>
        </linearGradient>
        <linearGradient id="b" gradientTransform="matrix(1 0 0 -1 0 114)" gradientUnits="userSpaceOnUse" x1="32.840279" x2="81.983269" y1="77" y2="77">
          <stop offset="0"/>
          <stop offset="1" stop-color="#0af"/>
        </linearGradient>
        <g transform="translate(-22.34043)">
          { !noBg && <path fill="#fff" d="m22.34043 0h101v88a8.00017 8.00017 0 0 1 -8 8h-85a8 8 0 0 1 -8-8z"/> }
          <path fill="url(#a)" d="m81.98368 20.5a5.71847 5.71847 0 0 0 -11.4286 0v33a5.61021 5.61021 0 0 0 5.7143 5.5h19.4287a5.504 5.504 0 1 0 0-11h-8.00008a5.61021 5.61021 0 0 1 -5.71429-5.5z"/>
          <path fill="url(#b)" d="m38.55458 15a5.61021 5.61021 0 0 0 -5.7143 5.5 5.61021 5.61021 0 0 0 5.7143 5.5h13.1429v27.5a5.71847 5.71847 0 0 0 11.4286 0v-27.5h13.14292a5.61021 5.61021 0 0 0 5.71429-5.5 5.61021 5.61021 0 0 0 -5.71429-5.5z"/>
          <g fill="#4d4d4d">
            <path d="m54.41148 65.6a4.168 4.168 0 0 1 1.6547.3305 4.27907 4.27907 0 0 1 1.3537.9089 4.46278 4.46278 0 0 1 .924 1.3428 4.06844 4.06844 0 0 1 .4083 1.6112v9.8019a1.3494 1.3494 0 0 1 -.4298 1.0122 1.44882 1.44882 0 0 1 -1.0099.3925 1.50386 1.50386 0 0 1 -1.0529-.3925 1.38476 1.38476 0 0 1 -.4083-1.0122v-9.6366a1.28521 1.28521 0 0 0 -.4298-.9916 1.36361 1.36361 0 0 0 -1.01-.4131 1.43549 1.43549 0 0 0 -1.0314.4131 1.28521 1.28521 0 0 0 -.4298.9916v9.6366a1.34968 1.34968 0 0 1 -.4297 1.0122 1.40084 1.40084 0 0 1 -1.01.3925 1.476 1.476 0 0 1 -1.0314-.3925 1.38476 1.38476 0 0 1 -.4083-1.0122v-9.6366a1.28521 1.28521 0 0 0 -.4298-.9916 1.38948 1.38948 0 0 0 -1.0314-.4131 1.43572 1.43572 0 0 0 -1.0315.4131 1.31713 1.31713 0 0 0 -.4083.9916v9.6366a1.34968 1.34968 0 0 1 -.4297 1.0122 1.42819 1.42819 0 0 1 -1.0315.3925 1.476 1.476 0 0 1 -1.0314-.3925 1.38476 1.38476 0 0 1 -.4083-1.0122v-9.8019a4.00464 4.00464 0 0 1 .3223-1.5699 4.46232 4.46232 0 0 1 .924-1.3427 4.785 4.785 0 0 1 1.3968-.9296 4.15461 4.15461 0 0 1 1.6976-.3512 4.00157 4.00157 0 0 1 1.5257.2892 5.67671 5.67671 0 0 1 1.3752.785 5.8046 5.8046 0 0 1 1.3323-.7643 3.97492 3.97492 0 0 1 1.5686-.3099z"/>
            <path d="m67.80388 78.2113a1.36353 1.36353 0 0 1 1.0099.4131 1.30447 1.30447 0 0 1 .4298.9709 1.34977 1.34977 0 0 1 -.4298 1.0122 1.4006 1.4006 0 0 1 -1.0099.3925h-6.1457a1.5004 1.5004 0 0 1 -1.01-.3925 1.34968 1.34968 0 0 1 -.4297-1.0122v-12.5906a1.38323 1.38323 0 0 1 .4082-.9709 1.35588 1.35588 0 0 1 1.0315-.4338h6.1457a1.36353 1.36353 0 0 1 1.0099.4131 1.327 1.327 0 0 1 .4298.9916 1.28485 1.28485 0 0 1 -.4298.9915 1.4006 1.4006 0 0 1 -1.0099.3925h-4.7275v3.455h3.2877a1.41 1.41 0 0 1 1.01.4131 1.35863 1.35863 0 0 1 0 1.9831 1.449 1.449 0 0 1 -1.01.3925h-3.2877v3.5789z"/>
            <path d="m79.43517 65.6a1.38949 1.38949 0 0 1 1.03141.4131 1.327 1.327 0 0 1 .4298.9916 1.28485 1.28485 0 0 1 -.4298.9915 1.42812 1.42812 0 0 1 -1.03141.3925h-2.40669v11.2066a1.38476 1.38476 0 0 1 -.4083 1.0122 1.55152 1.55152 0 0 1 -2.0629 0 1.38476 1.38476 0 0 1 -.4083-1.0122v-11.2066h-2.4282a1.47607 1.47607 0 0 1 -1.03141-.3925 1.31678 1.31678 0 0 1 -.40829-.9915 1.36061 1.36061 0 0 1 .40829-.9916 1.43553 1.43553 0 0 1 1.03141-.4131z"/>
            <path d="m86.318 65.6a5.3531 5.3531 0 0 1 1.97689.3718 5.0715 5.0715 0 0 1 2.79351 2.5409 4.3523 4.3523 0 0 1 .4298 1.9211v6.9615a11.80891 11.80891 0 0 1 -.4083 3.1296 1.37472 1.37472 0 0 1 -.967.4131 1.54731 1.54731 0 0 1 -1.00989-.3511 1.20512 1.20512 0 0 1 -.4083-.9296v-4.6169h-4.7919v4.5549a1.28268 1.28268 0 0 1 -.42981.9502 1.42267 1.42267 0 0 1 -.98849.3925 1.46645 1.46645 0 0 1 -.9884-.3718c-.2865-.2479-.42981-2.7509-.42981-3.1089v-6.8582a5.18605 5.18605 0 0 1 .40831-1.9418 5.01613 5.01613 0 0 1 2.7505-2.6648 5.32717 5.32717 0 0 1 2.06289-.3925zm2.4067 4.9991a2.06941 2.06941 0 0 0 -.1934-.8883 1.95941 1.95941 0 0 0 -.5157-.7437 2.44857 2.44857 0 0 0 -.7736-.4957 2.35548 2.35548 0 0 0 -.9455-.186 2.24824 2.24824 0 0 0 -.9025.186 2.407 2.407 0 0 0 -.75211.4544 2.16221 2.16221 0 0 0 -.5157.7024 2.02144 2.02144 0 0 0 -.19339.8676v1.8591h4.7919z"/>
            <path d="m101.03488 78.1906a1.33825 1.33825 0 0 1 .98849.4132 1.262 1.262 0 0 1 .42971.9708 1.35006 1.35006 0 0 1 -.42971 1.0123 1.37441 1.37441 0 0 1 -.98849.3924h-6.6184a1.42851 1.42851 0 0 1 -1.0315-.3924 1.55176 1.55176 0 0 1 -.4513-1.0123v-12.5906a1.26247 1.26247 0 0 1 .4298-.9709 1.462 1.462 0 0 1 1.053-.4131 1.38519 1.38519 0 0 1 .98839.4131 1.23622 1.23622 0 0 1 .45131.9709v11.2066z"/>
            <path d="m111.42238 78.1906a1.338 1.338 0 0 1 .9884.4132 1.26211 1.26211 0 0 1 .42979.9708 1.35013 1.35013 0 0 1 -.42979 1.0123 1.37419 1.37419 0 0 1 -.9884.3924h-6.6185a1.42849 1.42849 0 0 1 -1.03141-.3924 1.55175 1.55175 0 0 1 -.45129-1.0123v-12.5906a1.2625 1.2625 0 0 1 .42979-.9709 1.46192 1.46192 0 0 1 1.05291-.4131 1.3853 1.3853 0 0 1 .9885.4131 1.23644 1.23644 0 0 1 .45119.9709v11.2066z"/>
          </g>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={PATH_AFTER_LOGIN}>{logo}</RouterLink>;
}
