import {Container, Link, Stack, Typography} from "@mui/material";
import {PATH_APP, PATH_WEB} from "../../routes/paths";
import {Link as RouterLink} from 'react-router-dom';
import {styled} from "@mui/material/styles";
import Logo from "../../components/Logo";

const Foot = styled("footer")({
  minHeight: "100px",
  background: "#1976D2",
  marginTop: "100px",
  overflow: "hidden"
});

const Links = styled("ul")<{}>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  [theme.breakpoints.down('md')]: {
    flexDirection: "column"
  },

  li: {
    padding: "0 11px",
    listStyle: "none"
  },

  a: {
    transition: "opacity .4s ease-in-out",

    "&:hover": {
      opacity: 0.7,
      textDecoration: "none"
    }
  }
}));

interface Item {
  label: string;
  url?: string;
  internal?: boolean;
}

const itemsTop: Item[] = [
  { label: "Homepage", url: PATH_WEB.home },
  { label: "Marketplace", url: PATH_APP.general.browse, internal: true },
  { label: "Pricing", url: PATH_WEB.pricing },
  { label: "Support", url: PATH_WEB.support },
  { label: "About us", url: PATH_WEB.about },
  { label: "Contact", url: PATH_WEB.contact },
];

const itemsBot: Item[] = [
  { label: "©2022 Metal Euro, d.o.o. All Rights Reserved." },
  { label: "Privacy Policy" },
  { label: "Terms of Service" },
];

const itemMap = (item: Item) => {
  const text = <Typography color={"white"} fontWeight={500}>{item.label}</Typography>;

  if(!item.url) return text;

  if(item.internal){
    return <Link component={RouterLink} to={item.url}>{text}</Link>;
  }else{
    return <Link href={item.url}>{text}</Link>;
  }
};

const itemsMappedTop = itemsTop.map(itemMap);
const itemsMappedBot = itemsBot.map(itemMap);

export default function Footer() {


  return <Foot>
    <Container maxWidth={'lg'}>
      <Stack spacing={4} pb={4} alignItems={"center"}>
        <Logo />
        <Links>
          { itemsMappedTop.map(item => <li>{item}</li>) }
        </Links>
        <Links>
          { itemsMappedBot.map(item => <li>{item}</li>) }
        </Links>
      </Stack>
    </Container>
  </Foot>
}
