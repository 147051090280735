import {useEffect, useState} from 'react';
// @mui
import {Box, Grid, Card, Stack} from '@mui/material';
import {useSnackbar} from "notistack";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import RHFTextArea from "../../../components/hook-form/RHFTextArea";
import axios from "../../../utils/axios";
import {LoadingButton} from "@mui/lab";


// ----------------------------------------------------------------------

type Props = {

};

type FormValuesProps = {
  iban: string;
  receiver: string;
  note: string;
};

export default function Settings({ }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [ isLoading, setLoading ] = useState<boolean>(true);

  const SettingsSchema = Yup.object().shape({
    iban: Yup.string(),
    receiver: Yup.string(),
    note: Yup.string()
  });

  const defaultValues = {
    iban: "",
    receiver: "",
    note: ""
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SettingsSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/admin/slipInfo');
      setValue("iban", response.data.iban || "");
      setValue("note", response.data.note || "");
      setValue("receiver", response.data.receiver || "");
      console.log(response.data);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error fetching settings. Please refresh the page.");
    }

    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (data: FormValuesProps) => {
    let info: any = {
      iban: data?.iban || "",
      receiver: data?.receiver || "",
      note: data?.note || ""
    };

    setLoading(true);

    try {
      const response = await axios.post('/api/admin/slipInfo', info);
      enqueueSnackbar('Settings updated!');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }

    setLoading(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="iban" label="IBAN" />
              <RHFTextArea name="receiver" label="Receiver info" />
              <RHFTextArea name="note" label="Note" />
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          Save changes
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
