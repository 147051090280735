import {useEffect, useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Button,
  Container, Link, Typography, CircularProgress,
} from '@mui/material';
// redux
import {useDispatch, useSelector} from '../../redux/Store';
import {getMyItems} from '../../redux/repos/ItemRepo';
// routes
import {PATH_APP} from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// @types
import {Item} from '../../@types/item';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Image from "../../components/Image";
import Label from "../../components/Label";
import UniTable from "../../sections/UniTable";
import {getUsers} from "../../redux/repos/UserRepo";
import {User} from "../../@types/user";
import TableFilter from "../../sections/TableFilter";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {id: 'name', label: 'Name', align: 'left'},
  {id: 'status', label: 'Status', align: 'left', width: 120},
  {id: 'company', label: 'Company', align: 'left'},
  {id: ''},
];

// ----------------------------------------------------------------------

export default function AdminUsers() {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [subStatus, setSubStatus] = useState<string>("All");

  const searchTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => searchTimeout.current && clearTimeout(searchTimeout.current);
  }, []);

  const dispatch = useDispatch();

  const {data, loading, page, perPage} = useSelector((state) => state.userRepo);
  const users = data?.items || [];
  const total = data?.total || 0;

  const handleStatusChange = (subStatus: string) => {
    searchTimeout.current && clearTimeout(searchTimeout.current);
    searchTimeout.current = undefined;

    setSubStatus(subStatus);
    dispatch(getUsers(undefined, searchTerm.length == 0 ? undefined : searchTerm, subStatus != "All" ? subStatus == "Active" : undefined));
  }

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);

    searchTimeout.current && clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      searchTimeout.current = undefined;
      dispatch(getUsers(undefined, term.length == 0 ? undefined : term, subStatus != "All" ? subStatus == "Active" : undefined));
    }, 500);
  }

  const handlePageChange = (page: number) => {
    dispatch(getUsers(page));
  }

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const mapFunc = (user: User, index: number) => {
    const link = PATH_APP.admin.user(user.id);

    return [
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link to={link} component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {user.firstName} {user.lastName}
          </Typography>
        </Link>
      </Box>,

      <Typography align={"left"}><Label
        variant={"ghost"}
        color={user.enabled ? 'success' : 'error'}
        sx={{textTransform: 'capitalize'}}
      >{user.enabled ? 'Active' : 'Inactive'}</Label></Typography>,

      <Typography align={"left"}>{user.companyName}</Typography>,

      <Typography align={"right"}>
        <Button to={link} component={RouterLink}><Iconify icon={'eva:edit-fill'} />Edit</Button>
      </Typography>
    ];
  };

  return (
    <Page title="Users">
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading="Users"
          links={[
            {name: '', href: PATH_APP.root}
          ]}
          action={
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill"/>}
              component={RouterLink}
              to={PATH_APP.admin.userCreate}
            >
              Add new user
            </Button>
          }
        />

        <Card>
          <TableFilter search={searchTerm} onSearch={handleSearchChange} onFilter={handleStatusChange}/>
          <UniTable labels={TABLE_HEAD} items={users} isLoading={loading || searchTimeout.current != undefined} rowMap={mapFunc}
                    page={page} total={total} perPage={perPage} onChange={handlePageChange}/>
        </Card>
      </Container>
    </Page>
  );
}
