import { createSlice } from '@reduxjs/toolkit';
import {dispatch, store} from "./Store";
import {setupInterceptors} from "../utils/axios";

type AppState = {
  serverError: boolean;
}

const InitialState: AppState = {
  serverError: false
}

const slice = createSlice({
  name: 'app',
  initialState: InitialState,
  reducers: {
    setError(state){
      if(state.serverError) return;

      state = {
        ...state,
        serverError: true
      }

      return state;
    },

    clearError(state){
      if(!state.serverError) return;

      state = {
        ...state,
        serverError: false
      }

      return state;
    }
  }
});

// Reducer
export default slice.reducer;

export function setError(){
  return slice.actions.setError();
}

export function clearError(){
  return slice.actions.clearError();
}
