import {m} from 'framer-motion';
import {Typography} from '@mui/material';
import {varBounce} from '../../components/animate';
import {PageNotFoundIllustration} from '../../assets';

export default function Page404() {
  return <>
    <m.div variants={varBounce().in}>
      <Typography variant="h3" paragraph>
        Sorry, page not found!
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <Typography sx={{color: 'text.secondary'}}>
        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL?
        Be sure to check your spelling.
      </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
      <PageNotFoundIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
    </m.div>
  </>;
}
