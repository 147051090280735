import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import {useCallback, useState} from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// _mock
import { countries } from '../../../_mock';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../components/hook-form';
import axios from "../../../utils/axios";
import {User} from "../../../@types/user";
import InfoForm, {InfoFormValuesProps} from "../../../sections/UserEdit/InfoForm";

// ----------------------------------------------------------------------

type FormValuesProps = {
  firstName: string;
  lastName: string;
  email: string;
  address: string | null;
  city: string | null;
  postCode: string | null;
  country: string | null;
  companyName: string | null;
  vatNumber: string | null;
};

interface Props {
  user: User;
}

export default function General({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [ submitting, setSubmitting ] = useState<boolean>(false);

  const onSubmit = async (data: InfoFormValuesProps) => {
    if(submitting) return;
    setSubmitting(true);

    try {
      const response = await axios.put('/api/user', { ...data, id: user.id });
      enqueueSnackbar('User updated!');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }

    setSubmitting(false);
  };

  return (
    <InfoForm loading={false} submitting={submitting} onSubmit={onSubmit} user={user} />
  );
}
