import {Box, Card, CircularProgress, Stack} from "@mui/material";
import {FormProvider, RHFSelect, RHFTextField} from "../../components/hook-form";
import {countries} from "../../_mock";
import {LoadingButton} from "@mui/lab";
import {User} from "../../@types/user";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

export type InfoFormValuesProps = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  address: string | null;
  city: string | null;
  postCode: string | null;
  country: string | null;
  companyName: string | null;
  vatNumber: string | null;
};

interface Props {
  loading: boolean;
  submitting: boolean;
  user?: User;
  onSubmit: (values: InfoFormValuesProps) => void;
  onSaveClick?: () => void;
}

export default function InfoForm(props: Props){

  const { loading, submitting, user, onSubmit, onSaveClick } = props;

  const UpdateUserSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid address').required('Email address is required'),
    address: Yup.string(),
    city: Yup.string(),
    postCode: Yup.string(),
    country: Yup.string(),
    companyName: Yup.string(),
    vatNumber: Yup.string(),
  });

  const defaultValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phoneNumber: user?.phoneNumber || '',
    email: user?.email || '',
    address: user?.address || '',
    city: user?.city || '',
    postCode: user?.postCode || '',
    country: user?.country || '',
    companyName: user?.companyName || '',
    vatNumber: user?.vatNumber || ''
  };

  const methods = useForm<InfoFormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  if(loading){
    return <Card sx={{ p: 3 }}><CircularProgress sx={{ margin: "0 auto" }} /></Card>
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{
        p: 3,
        display: 'grid',
        rowGap: 3,
        gridTemplateColumns: { xs: 'repeat(1, 1fr)' }
      }}>
        <Box
          sx={{
            display: 'grid',
            rowGap: 3,
            columnGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <RHFTextField name="firstName" label="First name" />
          <RHFTextField name="lastName" label="Last name" />
        </Box>

        <Box
          sx={{
            display: 'grid',
            rowGap: 3,
            columnGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
          }}
        >
          <RHFTextField name="email" label="Email address" />
          <RHFTextField name="phoneNumber" label="Phone number" />
        </Box>
        <Box
          sx={{
            display: 'grid',
            rowGap: 3,
            columnGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >

          <RHFTextField name="address" label="Address" />
          <RHFTextField name="city" label="City" />
          <RHFTextField name="postCode" label="Post code" />
          <RHFSelect name="country" label="Country" placeholder="Country">
            <option value="" />
            {countries.map((option) => (
              <option key={option.code} value={option.code} selected={option.code == defaultValues.country}>
                {option.label}
              </option>
            ))}
          </RHFSelect>


          <RHFTextField name="companyName" label="Company name" />
          <RHFTextField name="vatNumber" label="Vat number" />
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton type="submit" variant="contained" loading={submitting} onClick={onSaveClick}>
            Save changes
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
