import {PATH_APP, PATH_AUTH, PATH_WEB} from "../../../routes/paths";
import {styled} from "@mui/material/styles";
import {Link, Typography} from "@mui/material";
import Iconify from "../../../components/Iconify";
import {useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";


const Items = [
  { title: "Homepage", link: PATH_WEB.home },
  { title: "Login", link: PATH_AUTH.login, internal: true, loginHidden: true },
  { title: "Register", link: PATH_AUTH.login, internal: true, loginHidden: true },
  { title: "Marketplace", link: PATH_APP.general.browse, internal: true },
  { title: "Pricing", link: PATH_WEB.pricing },
  { title: "Support", link: PATH_WEB.support },
  { title: "About us", link: PATH_WEB.about },
  { title: "Contact", link: PATH_WEB.contact }
];

const Dropdown = styled("ul")({
  display: "flex",
  flexDirection: "column",

  listStyle: "none",
  background: "#fff",
  borderRadius: "10px",
  padding: 0,
  margin: "0 !important",
  boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px rgba(0,0,0,0.14),0px 1px 3px rgba(0,0,0,0.12)",

  position: "absolute",
  width: "90%",
  left: "5%",
  right: "5%",
  top: "95px",

  transition: "transform 0.5s ease",
  transform: "translateY(-50%) scaleY(0)",

  "&.open": {
    transform: "translateY(0) scaleY(1)",
  }
});

const DropdownItem = styled("li")({
  background: "transparent",
  padding: 0,
  margin: 0,
  listStyle: "none",
  display: "block"
});

const DropdownLink = styled(Typography)({
  display: "block",
  fontWeight: 500,
  fontSize: "20px",
  lineHeight: "36px",
  textAlign: "center",
  padding: "10px 5%",
  borderBottom: "1px solid rgba(0,0,0,.03)",
  transition: "all .2s ease-in-out",
  textDecoration: "none",
  color: "#42A5F5",

  ":hover": {
    backgroundColor: "rgba(0,0,0,.03)",
    opacity: 0.7,
    textDecoration: "none"
  }
});

const Icon = styled(Iconify)({
  color: "#fff",
  width: "42px",
  height: "42px",
  cursor: "pointer"
});

export default function MobileMenu(){
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useAuth();

  const handleClick = () => {
    setOpen(!open);
  };

  const button = <Icon icon={open ? "charm:cross" : "charm:menu-hamburger"} onClick={handleClick} />;

  const menu = <Dropdown className={open ? "open" : undefined}>
    { Items.map(item => (item.loginHidden && isAuthenticated) ? undefined : <DropdownItem>
      { item.internal
        ? <Link component={RouterLink} to={item.link} sx={{ textDecoration: "none !important" }}><DropdownLink>{item.title}</DropdownLink></Link>
        : <Link href={item.link} sx={{ textDecoration: "none !important" }}><DropdownLink>{item.title}</DropdownLink></Link>
      }
    </DropdownItem>) }
  </Dropdown>;

  return <>
    {button}
    {menu}
  </>;
}
