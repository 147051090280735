// ----------------------------------------------------------------------

import {Item} from "../@types/item";
import {paramCase} from "change-case";
import {Subscription} from "../@types/subscription";
import {HOST_API} from "../config";
import {Category} from "../@types/category";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '';
const ROOTS_APP = '';
const ROOTS_WEB = 'https://tlmetall.com/en'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_APP = {
  root: ROOTS_APP,
  general: {
    browse: path(ROOTS_APP, '/browse'),
    category: (cat: Category) => path(ROOTS_APP, `/browse/${cat.slug}`),
    search: (term: string) => path(ROOTS_APP, `/search/${term}`),
    myItems: path(ROOTS_APP, '/my-items'),
    viewItem: (item: Item) => path(ROOTS_APP, `/item/${item.id}/${item.name ? paramCase(item.name) : ""}`),
    editItem: (item: Item) => path(ROOTS_APP, `/edit-item/${item.id}/`),
    newItem: path(ROOTS_APP, '/new-item'),
    account: path(ROOTS_APP, '/account'),

    app: path(ROOTS_APP, '/app'),
    ecommerce: path(ROOTS_APP, '/ecommerce'),
    analytics: path(ROOTS_APP, '/analytics'),
    banking: path(ROOTS_APP, '/banking'),
    booking: path(ROOTS_APP, '/booking'),
  },
  admin: {
    root: path(ROOTS_APP, "/admin/"),
    settings: path(ROOTS_APP, "/admin/"),
    users: path(ROOTS_APP, "/admin/users/"),
    user: (id: string) => path(ROOTS_APP, `/admin/user/${id}`),
    userCreate: path(ROOTS_APP, `/admin/users/new`),
    categories: path(ROOTS_APP, "/admin/categories"),
    catCreate: path(ROOTS_APP, `/admin/categories/new`),
    catEdit: (id: string) => path(ROOTS_APP, `/admin/category/${id}`)
  },
  mail: {
    root: path(ROOTS_APP, '/mail'),
    all: path(ROOTS_APP, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_APP, '/chat'),
    new: path(ROOTS_APP, '/chat/new'),
    view: (name: string) => path(ROOTS_APP, `/chat/${name}`),
  },
  calendar: path(ROOTS_APP, '/calendar'),
  kanban: path(ROOTS_APP, '/kanban'),
  permissionDenied: path(ROOTS_APP, '/permission-denied'),
  user: {
    root: path(ROOTS_APP, '/user'),
    new: path(ROOTS_APP, '/user/new'),
    list: path(ROOTS_APP, '/user/list'),
    cards: path(ROOTS_APP, '/user/cards'),
    profile: path(ROOTS_APP, '/user/profile'),
    account: path(ROOTS_APP, '/user/account'),
    edit: (name: string) => path(ROOTS_APP, `/user/${name}/edit`),
    demoEdit: path(ROOTS_APP, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_APP, '/e-commerce'),
    shop: path(ROOTS_APP, '/e-commerce/shop'),
    list: path(ROOTS_APP, '/e-commerce/list'),
    checkout: path(ROOTS_APP, '/e-commerce/checkout'),
    new: path(ROOTS_APP, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_APP, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_APP, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_APP, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_APP, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_APP, '/invoice'),
    list: path(ROOTS_APP, '/invoice/list'),
    new: path(ROOTS_APP, '/invoice/new'),
    view: (id: string) => path(ROOTS_APP, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_APP, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_APP, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_APP, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_APP, '/blog'),
    posts: path(ROOTS_APP, '/blog/posts'),
    new: path(ROOTS_APP, '/blog/new'),
    view: (title: string) => path(ROOTS_APP, `/blog/post/${title}`),
    demoView: path(ROOTS_APP, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_WEB = {
  home: path(ROOTS_WEB, "/"),
  pricing: path(ROOTS_WEB, "/cjenik/"),
  support: path(ROOTS_WEB, "/podrska/"),
  about: path(ROOTS_WEB, "/o-nama/"),
  contact: path(ROOTS_WEB, "/kontakt/")
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
