import Toolbar from "./Toolbar";
import {useState} from "react";
import {Box, Table, TableBody, TableContainer, TablePagination} from "@mui/material";
import {TableHeadCustom, TableNoData} from "../../components/table";
import Scrollbar from "../../components/Scrollbar";
import Row from "./Row";
import {OverridableStringUnion} from "@mui/types";
import {TablePropsSizeOverrides} from "@mui/material/Table/Table";

type Props<T> = {
  labels: any[];
  isLoading?: boolean;
  items: T[];
  rowMap: (item: T, index: number) => any[];
  total: number;
  page: number;
  perPage: number;
  onChange: (p: number) => void;
  emptyText?: string,
  size?: OverridableStringUnion<'small' | 'medium', TablePropsSizeOverrides>
};

export default function UniTable<T>(props: Props<T>) {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const {labels, isLoading, items, rowMap, total, page, perPage, onChange, emptyText, size} = props;

  return (
    <>
      { /* <Toolbar searchTerm={searchTerm} onSearchChange={term => setSearchTerm(term)}/> */ }

      <Scrollbar>
        <TableContainer sx={{position: 'relative'}}>
          <Table size={size || 'medium'}>
            <TableHeadCustom headLabel={labels}/>

            <TableBody>
              {isLoading || items.length == 0
                ? <TableNoData isNotFound={!isLoading && items.length == 0} notFoundText={emptyText} />
                : items.map((item, index) => <Row cells={rowMap(item, index)} key={`item-${index}`}/>)
              }

              { /* <TableEmptyRows height={80} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

              { /* <TableNoData isNotFound={isNotFound}/> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      { total > perPage && <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
        <TablePagination
          count={total}
          page={page-1}
          onPageChange={(event: unknown, newPage: number) => onChange(newPage+1)}
          rowsPerPage={perPage}
          rowsPerPageOptions={[]}
        />
      </Box> }
    </>
  );
}
