import {styled} from "@mui/material/styles";
import {ReactNode} from "react";


const Wrapper = styled("div")({
  position: "relative"
});

const Content = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  "& > *": {height: "100%", width: "100%"}
});

interface Props {
  children?: ReactNode,
  ratio?: number
}

export default function AspectRatioBox({children, ratio = 1}: Props) {

  return (
    <Wrapper>
      <Content>{children}</Content>
      <div style={{paddingBottom: (1 / ratio) * 100 + "%"}}/>
    </Wrapper>
  );
};
