import {Theme} from "@mui/material/styles";

export default function Container(theme: Theme) {
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: "lg"
      },

      styleOverrides: {
        maxWidthLg: {
          // maxWidth: "1080px !important"
        }
      }
    }
  };
};
