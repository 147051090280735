// @mui
import {
  Container,
  Grid,
  Card,
  InputAdornment,
  IconButton,
  Stack
} from '@mui/material';
// routes
import { PATH_APP } from '../../routes/paths';
// hooks
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import InfoForm, {InfoFormValuesProps} from "../../sections/UserEdit/InfoForm";
import {useState} from "react";
import {FormProvider, RHFSelect, RHFTextField} from "../../components/hook-form";
import Iconify from "../../components/Iconify";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import axios from "../../utils/axios";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
// sections

type FormProps = {
  password: string;
  subTier?: number;
};

const UpdateUserSchema = Yup.object().shape({
  password: Yup.string().required('Password is required').min(8, 'Must be at least 8 characters'),
  subTier: Yup.number()
});

export default function UserCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [ submitting, setSubmitting ] = useState<boolean>(false);
  const [ showPass, setShowPass ] = useState<boolean>(false);

  const methods = useForm<FormProps>({
    resolver: yupResolver(UpdateUserSchema),
    mode: "onBlur"
  });

  const {
    getValues,
    trigger
  } = methods;

  const handleSubmit = async (values: InfoFormValuesProps) => {
    if(submitting) return;

    const success = await trigger();
    if(!success) return;

    setSubmitting(true);

    const { password, subTier } = getValues();

    try {
      const response = await axios.post('/admin/users', { ...values, password });
      navigate(PATH_APP.admin.user(response.data.user.id));
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message);
    }

    setSubmitting(false);
  }

  return (
    <Page title={"Add new user"}>
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading={"Add new user"}
          links={[
            { name: '', href: PATH_APP.root }
          ]}
        />

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormProvider methods={methods}>
              <Card sx={{p: 3}}>
                <Stack spacing={3}>
                  <RHFTextField
                    name="password"
                    label="Password"
                    type={showPass ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={() => setShowPass(!showPass)}>
                            <Iconify icon={showPass ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Card>
            </FormProvider>
          </Grid>
          <Grid item xs={8}>
            <InfoForm loading={false} submitting={submitting} onSubmit={handleSubmit} onSaveClick={() => trigger()} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
