import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, Link } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import useAuth from "../../../hooks/useAuth";
import {PATH_APP} from "../../../routes/paths";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'My items',
    linkTo: PATH_APP.general.myItems
  },
  {
    label: 'Account settings',
    linkTo: PATH_APP.general.account
  }
];

const ADMIN_OPTIONS = [
  { label: "Admin area", linkTo: PATH_APP.admin.root },
  { label: "Users", linkTo: PATH_APP.admin.users },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { logout, isAuthenticated, user } = useAuth();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const isAdmin = isAuthenticated && (user?.roles || []).indexOf("ROLE_ADMIN") != -1;

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[100], 0.2),
            },
          }),
        }}
      >
        <Avatar
          src="/assets/icons/navbar/ic_user.svg"
          alt="Profile"
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            { `${user?.firstName} ${user?.lastName}` }
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            { user?.email }
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose} to={option.linkTo} component={RouterLink}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        { isAdmin && <>
            <Stack sx={{ p: 1 }}>
              {ADMIN_OPTIONS.map((option) => (
                <MenuItem key={option.label} onClick={handleClose} to={option.linkTo} component={RouterLink}>
                  {option.label}
                </MenuItem>
              ))}
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />
        </> }

        <MenuItem sx={{ m: 1 }} onClick={logout}>Logout</MenuItem>
      </MenuPopover>
    </>
  );
}
